.main {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0;
  padding: 50px 0 50px 0;
  background-image: url("../../../../../assets/images/bg_dotted.png");
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
}
.content {
  padding-inline: calc(var(--common-padding-inline) - 5.6rem);
  display: flex;
  flex-direction: column;
  gap: 50px;

}
.items {
  display: flex;
  flex-wrap: wrap;

  gap: 50px; /* Add spacing between cards if needed */
}
.items > :nth-child(2n) {
  justify-content: flex-end;
}
.header {
  color: var(--main-beet, #ff527a);
  margin-left: 78px;
  margin-bottom: 0;
  font: 600 38px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .header {
    margin-left: 10px;
  }
}
