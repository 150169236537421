.heroContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 1);
}

.heroContent {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 44.9rem;
  width: 100%;
  align-items: start;
  justify-content: center;
  
}

.heroBackground {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(1.5);
}

.heroTitle {
  position: relative;
  font-size: 5rem;
  font-weight: 600;
  line-height: 52px;
  max-width: 75%;

  padding-inline: var(--common-padding-inline);
}

.heroSubtitle {
  position: relative;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1;
  padding-inline: var(--common-padding-inline);

}

@media (max-width: 991px) {
  .heroContent {
    max-width: 100%;
  }

  .heroTitle {
    max-width: 100%;
    font-size: 3rem;
    line-height: 46px;
  }

  .heroSubtitle {
    margin-bottom: 10px;
  }
}
