.container {
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.heading {
  color: var(--color-text, #596475);
  font: 600 38px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.description {
  color: var(--color-text, #596475);
  margin-top: 8px;
  font: 400 22px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.subheading {
  color: var(--color-text, #596475);
  margin-top: 42px;
  font: 600 28px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.mainGrid {
  width: 100%;
  margin-top: 24px;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  width: 100%;
}

.secondaryGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 35px;
}

.moreText {
  color: var(--color-text, #596475);
  font: 600 28px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
  display: flex;
  align-self: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .heading,
  .description {
    max-width: 100%;
  }

  .subheading {
    margin-top: 40px;
  }

  .cardGrid,
  .secondaryGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 38px;
  }

  /* Center the last item if the number of items is odd */
  .cardGrid > :nth-child(odd):last-child,
  .secondaryGrid > :nth-child(odd):last-child {
    grid-column: span 2;
    justify-self: center;
  }
}
