.faqContainer {
  display: flex;
  position: relative; /* Ensure relative positioning to contain the absolutely positioned image */
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-inline: var(--common-padding-inline);
  padding-top: 50px;
  padding-bottom: 50px;
  scroll-margin-top: 100px;
}
.title {
  color: var(--main-beet, #ff527a);
  font-family: "AlmoniRegular";
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 105.263% */
}
.topLeaf {
  position: absolute;
  rotate: 90deg;
  transform: scaleY(-1);
  right: 8px;
  top: -8px;
}
.bottomLeaf {
  position: absolute;
  left: 0;
  bottom: 0;
}
.img {
  width: 182px;
  height: 198px;
  flex-shrink: 0;
}
